import React from 'react'
import Navigation from './navigation'
import Footer from './footer'


const Layout = ({ children }) => {
  return (
    <div>
      <Navigation />
      <div className='relative container mx-auto h-screen px-4 xl:px-20 pt-10 md:pt-20'>
        <main className='h-full'>
            {children}
        </main>
      </div>
      <Footer />
    </div>
  )
}

export default Layout