import React from "react"


const Navigation = () => {
    
    return (
        <>
            <div className="fixed top-0 w-full flex items-center h-10 md:h-20 bg-neutral-100 py-2 text-neutral-500 z-10">
                <div className="relative container flex flex-row mx-auto items-center px-4 xl:px-20">
                    <h1 className="mx-auto font-courierprime text-center text-2xl md:text-5xl">Lewis Enterprises</h1>
                </div>
            </div>
        </>
    )
}




  
export default Navigation