import React from "react"

const Footer = () => {
    return (
      <div>
        <footer>
            <div></div>
        </footer>
      </div>
    )
  }
  
  export default Footer